// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

// gatsby-browser.js and gatsby-ssr.js
import React from "react"
import { GlobalProvider } from "./src/utils/GlobalContext"
import { GaBackground } from "./src/components/ga-spacetime" // Adjust the path as necessary

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>
    <React.Suspense fallback={<div>Loading...</div>}>
      <div className="ga-backround-wrapper">
        <GaBackground />
      </div>
      {element}
    </React.Suspense>
  </GlobalProvider>
)
