import React from "react"
import openSimplexNoise from "./simplexNoise"
const ReactP5Wrapper = React.lazy(() =>
  import("react-p5-wrapper").then(module => ({
    default: module.ReactP5Wrapper,
  }))
)

const sketchSpacetime = p5 => {
  // V1 - Space-time structure and energies | Lines as rectangles with flow field
  // Performance increase - enable for debugging
  p5.constructor.disableFriendlyErrors = true // disables FES
  let bodyWidth = document.getElementsByTagName("body")[0].clientWidth
  let bodyHeight = document.getElementsByTagName("body")[0].clientHeight
  let canvasWidth =
    bodyWidth < window.innerWidth ? window.innerWidth : bodyWidth
  let canvasHeight =
    bodyHeight < window.innerHeight ? window.innerHeight : bodyHeight

  // Space-time field Parameters
  let inc = 0.07 // 0.07 - Increment of noise values
  let zInc = 0.0002 // 0.0002
  let scl = 30 // 30 - Ratio of cell size to width in pixels
  let zoff = 0
  let yoff = 0
  let xoff = 0
  let timeMultiplier = 0.5

  let cols, rows, simplexNoise
  let rColor, rGreen, rBlue, setShuffle

  p5.windowResized = () => {
    bodyWidth = document.getElementsByTagName("body")[0].clientWidth
    bodyHeight = document.getElementsByTagName("body")[0].clientHeight
    canvasWidth = bodyWidth < window.innerWidth ? window.innerWidth : bodyWidth
    canvasHeight =
      bodyHeight < window.innerHeight ? window.innerHeight : bodyHeight
    p5.resizeCanvas(canvasWidth, canvasHeight)
    cols = p5.floor(canvasWidth / scl) + 2
    rows = p5.floor(canvasHeight / scl) + 2
  }

  // p5.updateWithProps = props => {
  //   if (props.gavalues?.params?.inc) {
  //     inc = props.gavalues.params.inc
  //   }
  //   if (props.gavalues?.params?.zInc) {
  //     zInc = props.gavalues.params.zInc
  //   }
  //   if (props.gavalues?.params?.scl) {
  //     scl = props.gavalues.params.scl
  //     cols = p5.floor(canvasWidth / scl) + 2
  //     rows = p5.floor(canvasHeight / scl) + 2
  //   }
  // }

  p5.mouseClicked = () => {
    generateRandomStructure()
  }

  p5.setup = () => {
    // p5.frameRate(60); // KEEP IT LIKE THIS
    simplexNoise = openSimplexNoise(Date.now()).noise3D
    p5.background(0)
    generateRandomStructure()
  }

  p5.draw = () => {
    p5.background(0)
    spacetimeField()
  }

  function generateRandomStructure() {
    const scaleFactor = p5.random(0.3, 1.1)
    // Calculate scaled canvas size
    const scaledWidth = canvasWidth * scaleFactor
    const scaledHeight = canvasHeight * scaleFactor
    // Create canvas at scaled size
    p5.createCanvas(scaledWidth, scaledHeight)
    // Use CSS to scale the canvas up to fill the screen
    p5.canvas.style.width = "100%"
    p5.canvas.style.height = "100%"
    cols = p5.floor(scaledWidth / scl) + 2
    rows = p5.floor(scaledHeight / scl) + 2

    rGreen = p5.random(25, 255)
    rBlue = p5.random(25, 255)
    setShuffle = p5.floor(p5.random(1, 4))
    p5.strokeWeight(p5.floor(p5.random(6, 10)))
    inc = p5.random(0.01, 0.15)
    zInc = p5.random(0.00009, 0.0003)
  }

  function spacetimeField() {
    yoff = 0
    for (let y = -1; y < rows; y++) {
      xoff = 0
      for (let x = -1; x < cols; x++) {
        // Angle determines flow result - / 2 * PI determine the general direction
        let angle = (simplexNoise(xoff, yoff, zoff) / 2) * p5.PI
        let v = p5.constructor.Vector.fromAngle(angle)
        let rAlpha = p5.map(angle, 0, 3, 70, 255)
        rColor = p5.map(angle, 0, 1, 20, 255)

        p5.push()
        p5.translate(x * scl, y * scl)
        p5.rotate(v.heading())

        // Set stroke based on shuffled option
        switch (setShuffle) {
          case 1:
            p5.stroke(rColor, rGreen, rBlue, rAlpha)
            break
          case 2:
            p5.stroke(rBlue, rColor, rGreen, rAlpha)
            break
          default:
            p5.stroke(rGreen, rBlue, rColor, rAlpha)
        }

        for (let i = 0; i < 5; i++) {
          p5.line(0, i * 7, scl, i * 7)
        }
        p5.pop()

        xoff += inc
      }
      yoff += inc
      zoff += zInc * timeMultiplier
    }
  }
}

export function GaBackground(params) {
  const isSSR = typeof window === "undefined"
  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div className="background-only"></div>}>
          <ReactP5Wrapper sketch={sketchSpacetime} />
        </React.Suspense>
      )}
    </>
  )
}
